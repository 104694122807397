import React from "react";
import ResetPasswordForm from "../components/ResetPasswordForm";
import { Flex } from "../elements/Flex";

const ResetPasswordPage = () => {
  return (
    <Flex bg="pink" px={3} py={[5, 5, 7]}>
      <ResetPasswordForm />
    </Flex>
  );
};

export default ResetPasswordPage;
