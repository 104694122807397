import React, { useState } from "react";
import { Button } from "../elements/Button";
import { Form } from "../elements/Form";
import { Input } from "../elements/Input";
import { Text } from "../elements/Text";
import axios from "axios";
import queryString from "query-string";
import { Link } from "gatsby";

const ResetPasswordForm = () => {
  const [state, setState] = useState({
    passwordResetMessage: "",
    validationMessage: "",
    password: "",
    confirmPassword: "",
  });

  const { passwordResetMessage, validationMessage, password, confirmPassword } = state;

  const search = typeof window !== "undefined" && window.location.search;

  const resetPasswordClicked = (e) => {
    e.preventDefault();

    const { password, confirmPassword } = state;
    if (!password || !confirmPassword) {
      setState({
        ...state,
        validationMessage: "You must enter your password in both fields",
      });
    } else if (password !== confirmPassword) {
      setState({
        ...state,
        validationMessage: "Your passwords do not match",
      });
    } else if (password.length < 6) {
      setState({
        ...state,
        validationMessage: "Your password must be at least 6 characters long",
      });
    } else {
      const { code: resetCode } = queryString.parse(search);

      axios({
        method: "post",
        url: `https://7efpy5nte8.execute-api.eu-west-1.amazonaws.com/prod/reset-account-password`,
        data: {
          resetCode,
          password,
        },
      })
        .then((response) => {
          if (response.data.errorMessage) {
            setState({
              validationMessage: response.data.errorMessage,
            });
          } else {
            setState({
              passwordResetMessage: response.data.message,
            });
          }
        })
        .catch((error) => {
          setState({
            passwordResetMessage: error.response.data.errorMessage,
          });
        });
    }
  };

  return (
    <Form
      onSubmit={resetPasswordClicked}
      bg="white"
      py={3}
      px={3}
      textAlign="center"
      width="100%"
      maxWidth="500px"
      mx="auto"
    >
      {passwordResetMessage ? (
        <>
          <Text mb={3}>{passwordResetMessage}</Text>
          <Link to="/app/login">
            <Text color="midGrey">Login</Text>
          </Link>
        </>
      ) : (
        <>
          <Text my={3} fontSize={25} fontWeight={600}>
            RESET PASSWORD
          </Text>

          <Text mb={3}>
            Please enter your email below to recover your account using an email code.
          </Text>

          {validationMessage && (
            <Text color="red" fontWeight={600} mb={3}>
              {validationMessage}
            </Text>
          )}

          <Input
            variant="authentication"
            id="password"
            placeholder="New Password"
            type="password"
            required
            onChange={({ target }) => {
              setState({
                ...state,
                password: target.value,
              });
            }}
          />

          <Input
            variant="authentication"
            id="confirm-password"
            placeholder="Confirm New Password"
            type="password"
            required
            onChange={({ target }) => {
              setState({
                ...state,
                confirmPassword: target.value,
              });
            }}
          />

          <Button
            bg="pink"
            width="100%"
            color="white"
            p={2}
            border="none"
            borderRadius="5px"
            fontWeight="600"
            onClick={resetPasswordClicked}
            fontSize={17}
          >
            Reset
          </Button>
        </>
      )}
    </Form>
  );
};

export default ResetPasswordForm;
